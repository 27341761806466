
 <template>
  <div class="modal-card modal-work-flow-content">
    <header class="modal-card-head">
      <div class="columns custom-card-head" style="width: 100%">
        <span class="hearder-liquidation">Nhận quỹ</span>
      </div>
    </header>
    <section class="modal-card-body">
      <p>Bạn đang nhận được lệnh chuyển quỹ từ:</p>
      <p class="fund-info">
        {{
          item.FundTransferDetail.FullName !== null
            ? item.FundTransferDetail.FullName
            : ""
        }}
        -
        {{
          item.FundTransferDetail.ShopName !== null
            ? item.FundTransferDetail.ShopName
            : ""
        }}
      </p>
      <span>Số tiền: </span
      ><span class="money-fund">{{ formatCurrency(item.Total) }}</span>
    </section>
    <footer class="modal-card-foot">
      <div class="center-button">
        <div class="footer-border-content-validate">
          <a @click="$emit('close')">Đóng</a>
          <b-button class="btn-next" @click.prevent="confirmFundReceive" :disabled="isDisabled">
            Xác nhận
          </b-button>
        </div>
      </div>
    </footer>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import FundService from "@/modules/common/services/FundService.vue";
import Enum from "@/helpers/Enum.vue";
import ToastHelper from "@/helpers/OpenToast.vue";
import store from "@/store";
export default {
  props: ["item"],
  data() {
    return {
      isDisabled:false,
      dateLiquidation: "",
      reasonForExtensions: [
        {
          Id: 1,
          Code: "01",
          Name: "Lý do khách quan",
        },
        {
          Id: 2,
          Code: "02",
          Name: "Mất khả năng thanh toán",
        },
      ],
      liquidationInfo: {},
      totalMoneyLiquidation: null,
      isLoading: false,
      note: "",
      position: null,
    };
  },
  methods: {
    async confirmFundReceive() {
      var _self = this;
      if (_self.isDisabled) return;

      _self.isDisabled=true;

      setTimeout(async () => {
        try {
        _self.isLoading = true;
        var request = {
            amount: _self.item.Total.toString(),
            note: _self.item.Note,
            referenceSourceId: "4", // Mã kênh thực hiện (0: Mifos 1: Los 2: App 3: Bao hiem )
            referenceTransactionType: "74", // Nhận quỹ
            paymentType: "1", // Tiền mặt
            transationType: "1", // 1: Thu 2: Chi
            office: _self.item.FundReceiveDetail.BusinessOfficeCode,
            createdBy:store.state.user.Profile.EmployeeCode,
            userOfficeCode:_self.item.FundReceiveDetail.BusinessOfficeCode,
            staffCode: store.state.user.Profile.EmployeeCode,
            detail: {
              officeCodeTransaction: _self.item.FundTransferDetail.BusinessOfficeCode,
              receiveMethodTransaction: _self.item.FundTransferDetail.BankName,
              receiveMethod: _self.item.FundReceiveDetail.BankName,
              receiptCode: _self.item.FundReceiveDetail.CodePrint,
              referenceReceiptCode: _self.item.FundTransferDetail.CodePrint,
              businessType: Enum.BUSINESS_TYPE.CQ,
              transferType: _self.item.TypeMethod,
              transactionId: _self.item.FundReceiveDetail.TransactionCode,
              referenceTransactionId: _self.item.FundTransferDetail.TransactionCode,
              employeeCode:_self.item.FundTransferDetail.EmployeeCode
            },
          };
          if (
            _self.item.CategoryMethods.Code === Enum.Method_Receive.CashToCash ||
            _self.item.CategoryMethods.Code === Enum.Method_Receive.BankToCash
          ) {
            request.paymentType = "1";
          } else if (
            _self.item.CategoryMethods.Code === Enum.Method_Receive.BankToBank ||
            _self.item.CategoryMethods.Code === Enum.Method_Receive.CashToBank
            ||  _self.item.CategoryMethods.Code === Enum.Method_Receive.WalletToBank
          ) {
            request.paymentType = "2";
          }

          let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
          if (_self.item.FundTransferDetail.BusinessOfficeCode == Enum.OFFICE.HO) {
            request.detail.officeCodeTransaction = Enum.OFFICE.HO;
          }
          //Nếu user đăng nhập thuộc hội sở, cùng OU
          if (shopSelected.BusinessOfficeCode === Enum.OFFICE.HO) {
            request.office = Enum.OFFICE.HO;
            if (
              _self.item.FundReceiveDetail.ShopCode ===
              _self.item.FundTransferDetail.ShopCode
            ) {
              // Nếu loại giao dịch là: TM-NH
              if (
                _self.item.CategoryMethods.Code === Enum.Method_Receive.CashToBank
              ) {
                request.referenceTransactionType = "83"; // Thu tiền ngân hàng
              } else if (
                //loại giao dịch là: TM-TM
                _self.item.CategoryMethods.Code === Enum.Method_Receive.CashToCash
              ) {
                request.referenceTransactionType = "93"; // Nhận tiền
              }
            }
          }
          if(_self.item.CategoryMethods.Code === Enum.Method_Receive.BankToWallet)
         {
            request.referenceTransactionType = Enum.TRANTYPEMIFOS.Wallet.Re;
            request.paymentType=Enum.PAYMENT_TYPE.Wallet;
          }
          if(_self.item.CategoryMethods.Code === Enum.Method_Receive.WalletToBank)
          {
            request.referenceTransactionType = Enum.TRANTYPEMIFOS.Wallet.Re;
          }
          if(_self.item.CategoryMethods.Code === Enum.Method_Receive.GPayToBank)
            {
            request.referenceTransactionType = Enum.TRANTYPEMIFOS.GPay.Re;
            request.paymentType=Enum.PAYMENT_TYPE.Bank;
            }
          let response = await FundService.Post_CreateTransactionFund(
            _self.item.Id,
            1,
            store.state.user.Profile.FullName,
            request
          );
          _self.isLoading = false;
          if (response) {
            ToastHelper.OpenToast("Nhận quỹ thành công", "is-success");
          } else {
            ToastHelper.OpenToast("Nhận quỹ không thành công", "is-danger");
          }
          _self.$emit("close");
          _self.$emit("loadData");
        }
        catch(ex) { console.log(ex) }

        _self.isDisabled=false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-work-flow-content {
  width: 600px !important;
}
.custom-card-head {
  font-size: 14px;
}
.reload-work-flow {
  font-family: Roboto;
  text-decoration: underline;
  color: $color-light-blue;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}
.close-work-flow {
  font-family: Roboto;
  text-decoration: underline;
  color: #56595c;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}
.card-foot-work-flow {
  padding-left: 300px;
}
.customer-phone {
  background: #f1f2f4 !important;
  border-radius: 4px;
  padding: 5px;
  margin-left: 5px;
  color: #4e64ff;
}
.column-card-head-1 {
  border-right: 2px solid #e2e7ea;
  margin-top: 10px;
  margin-bottom: 10px;
}
.center-button {
  margin: auto;
}

.hearder-liquidation {
  font-size: 14px;
  font-style: normal;
  font-family: Roboto;
  margin-left: 10px;
  font-weight: bold;
}
.txt-label-info {
  font-size: 13px;
}

.footer-border-content-validate {
  a {
    text-decoration: underline;
    margin-right: 30px;
    margin-top: 8px;
    color: #717171;
  }
}
.btn-next {
  color: white !important;
  background-color: #2cb66b !important;
}
.fund-info {
  font-weight: bold;
}
.money-fund {
  color: #2cb66b;
  font-weight: bold;
}
</style>