<template>
  <section>
    <div class="columns">
      <div class="column">
        <p class="level-right">{{ CurrentDate("DD/MM/YYYY") }}</p>
      </div>
    </div>
    <div class="columns">
      <div class="column is-4">
        <p><b>Đơn vị:</b> {{ item.FundReceiveDetail.ShopName }}</p>
        <p><b>Địa chỉ: </b> {{ item.FundReceiveDetail.ShopAddress }}</p>
      </div>
      <div class="column is-4 has-text-centered">
        <div class="print-title">Phiếu thu</div>
        <b>Số: {{ item.FundReceiveDetail.CodePrint }}</b>
        <br />
        <i
          >Ngày {{ CurrentDate("DD") }} Tháng {{ CurrentDate("MM") }} Năm
          {{ CurrentDate("YYYY") }}</i
        >
      </div>
      <div class="column is-4">
        <p><b>Mẫu số 02-TT</b></p>
        (Ban hành theo QĐ số: 15/2006/QĐ-BTC Ngày 20/03/2006 của bộ trưởng BTC)
        <p><b>Nợ:</b></p>
        <p><b>Có:</b></p>
      </div>
    </div>
    <div class="columns height-30">
      <div class="column" v-if="(item.CategoryMethods.Code===Enum.Method_Receive.CashToBank)||item.FundReceiveDetail.ShopCode==Enum.OFFICE.HO">
        Họ tên người nộp tiền: <b>{{ item.FundReceiveDetail.FullName }}, F88 HỘI SỞ</b>
      </div>
        <div class="column" v-else>
        Họ tên người nộp tiền: <b> {{ item.FundReceiveDetail.FullName }}, {{item.FundReceiveDetail.ShopName}}</b>
      </div>
      <!-- <div class="column">
        Họ tên người nộp tiền:
        <b>
          {{ item.FundReceiveDetail.FullName }},
          {{ item.FundReceiveDetail.ShopName }}</b
        >
      </div> -->
    </div>
    <div class="columns height-30">
      <div class="column is-2">Địa chỉ:</div>
      <div class="column is-10">
        {{ item.FundTransferDetail.ShopAddress }}
      </div>
    </div>
    <div class="columns height-30">
      <div class="column is-2">Lý do nộp:</div>
      <div class="column is-10">
        {{ item.Note }}
      </div>
    </div>
    <div class="columns height-30">
      <div class="column is-2">Số tiền:</div>
      <div class="column is-3">
        <b>{{ item.Total.toLocaleString() }}</b> vnđ
      </div>
      <div class="column is-7">
        <i>(Số tiền bằng chữ: {{ IntToString(item.Total) }} đồng)</i>
      </div>
    </div>
    <div class="columns height-30">
      <div class="column is-2">Kèm theo:</div>
      <div class="column is-10">Chứng từ gốc</div>
    </div>
    <div class="columns height-30">
      <div class="column is-4 is-offset-8 has-text-centered">
        <p>
          <i
            >Ngày {{ CurrentDate("DD") }} Tháng {{ CurrentDate("MM") }} Năm
            {{ CurrentDate("YYYY") }}</i
          >
        </p>
      </div>
    </div>
    <div class="columns height-180">
      <div class="column is-3 has-text-centered">
        <p><b>Người nộp tiền</b></p>
        <p><i>(Ký, họ tên)</i></p>
      </div>
      <div class="column is-3 has-text-centered">
        <p><b>Người lập</b></p>
        <p><i>(Ký, họ tên)</i></p>
      </div>
      <div class="column is-2 has-text-centered">
        <p><b>Thủ quỹ</b></p>
        <p><i>(Ký, họ tên)</i></p>
      </div>
      <div class="column is-4 has-text-centered">
        <p><b>Phó Tổng Giám Đốc</b></p>
        <p><i>(Ký, họ tên)</i></p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <p>
          Đã nhận đủ số tiền (viết bằng chữ):
          {{ IntToString(item.Total) }} đồng
        </p>
        <p>+ Tỷ giá ngoại tệ (vàng, bạc, đá quý):</p>
        <p>+ Số tiền quy đổi:</p>
        (Liên gửi ra ngoài đóng dấu)
      </div>
    </div>
  </section>
</template>

<script>
import Regex from "@/helpers/Regex.vue";
import ConvertDatetime from "@/helpers/ConvertDatetime.vue";
import Enum from "@/helpers/Enum.vue";
export default {
  props: ["item", "code"],
  data() {
    return {
      Enum: Enum,
    };
  },
  methods: {
    CurrentDate(format) {
      return ConvertDatetime.ConvertDatetimeToFormat(new Date(), format);
    },
    IntToString(value) {
      return Regex.DocTienBangChu(value);
    },
  },
};
</script>
