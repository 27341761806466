<template>
  <div>
    <div class="order">
      <div>{{ offset + idx + 1 }}</div>
    </div>
    <div class="col">
      <div class="columns">
        <template v-if="data.Status.Code !== $getConst('FUNDSTATUS').Cancelled">
          <div class="column is-2 custom-w-10 text-align">
            {{ ConvertToDateTime.ConvertUnixToDatetime(data.CreatedDate) }}
          </div>
          <div class="column is-2 word-wrap">
            {{ data.FundTransferDetail.FullName }}
            <br />
            {{ data.FundTransferDetail.ShopName }}
          </div>
          <div class="column is-1">
            {{ data.FundReceiveDetail.FullName }}
          </div>
          <div class="column is-2 word-wrap">
            {{ data.UpdateFullName }}<br />
            {{ data.UpdateFullName ? data.FundReceiveDetail.ShopName : "" }}
          </div>
          <div class="column is-1 money-receive">
            {{ formatCurrency(data.Total) }}
          </div>
          <div class="column is-1">
            {{ data.TypeMethod }}
          </div>
          <div class="column is-1 word-wrap">{{ data.Note }}</div>
        </template>
        <template v-else>
          <div class="column is-2 del text-align custom-w-10">
            {{ ConvertToDateTime.ConvertUnixToDatetime(data.CreatedDate) }}
          </div>
          <div class="column is-2 del word-wrap">
            {{ data.FundTransferDetail.FullName }}
            <br />
            {{ data.FundTransferDetail.ShopName }}
          </div>
          <div class="column is-1 del">
            {{ data.FundReceiveDetail.FullName }}
          </div>
          <div class="column is-2 del word-wrap">
            {{ data.UpdateFullName }}<br />
            {{ data.UpdateFullName ? data.FundReceiveDetail.ShopName : "" }}
          </div>
          <div class="column is-1 del">{{ formatCurrency(data.Total) }}</div>
          <div class="column is-1 del">
            {{ data.TypeMethod }}
          </div>
          <div class="column is-1 del word-wrap">{{ data.Note }}</div>
        </template>

        <div class="column is-1">
          <span :style="{ color: data.Status.Color }">
            <b-icon icon="checkbox-blank-circle" size="is-small"></b-icon>
            <b-tooltip
              v-if="
                data.Status.Code === $getConst('FUNDSTATUS').Complete &&
                data.FundReceiveDetail.BankCode != '33'
              "
              :label="dateReceive + ' - ' + data.FundReceiveDetail.FullName"
              type="is-white"
              position="is-bottom"
            >
              <b class="pawn-status">{{ data.Status.Name }}</b>
            </b-tooltip>
            <b-tooltip
              v-if="
                data.Status.Code === $getConst('FUNDSTATUS').Complete &&
                data.FundReceiveDetail.BankCode == '33'
              "
              :label="
                dateReceive +
                ' - ' +
                data.FundReceiveDetail.FullName +
                ' - ' +
                data.FundReceiveDetail.QueueCode
              "
              type="is-white"
              position="is-bottom"
            >
              <b class="pawn-status">{{ data.Status.Name }}</b>
            </b-tooltip>
            <b
              class="pawn-status"
              v-if="data.Status.Code !== $getConst('FUNDSTATUS').Complete"
              >{{ data.Status.Name }}</b
            >
            <p
              v-if="data.Status.Code === $getConst('FUNDSTATUS').Cancelled"
              class="note-cancel"
            >
              <b-tooltip
                :label="data.ReasonCancel"
                type="is-white"
                position="is-bottom"
              >
                Lý do
              </b-tooltip>
            </p>
          </span>
        </div>
        <div
          class="column is-2 btn-action"
          v-if="data.Status.Code === $getConst('FUNDSTATUS').WaitConfirm"
        >
          <div class="button-action-receive">
            <b-button
              class="button-cancel"
              outlined
              :disabled="isDisableButton"
              @click.prevent="onMenuClick(1)"
              v-if="
                checkAccessRole(
                  $getConst('MODULE').Mifos,
                  $getConst('ACTION').FundReceive.Delete
                )
              "
            >
              Hủy
            </b-button>

            <b-button
              class="button-confirm"
              outlined
              :disabled="shopSelected==Enum.OFFICE.HO"
              @click.prevent="onMenuClick(2)"
              v-if="
                checkAccessRole(
                  $getConst('MODULE').Mifos,
                  $getConst('ACTION').FundReceive.Approve
                ) && shopSelected!=Enum.OFFICE.HO
              "
            >
              Xác nhận
            </b-button>
            <b-dropdown aria-role="list" v-if="
                checkAccessRole(
                  $getConst('MODULE').Mifos,
                  $getConst('ACTION').FundReceive.Approve
                ) && shopSelected==Enum.OFFICE.HO
              "
              
              position="is-top-left">
              <template #trigger="{ active }">
                <b-button
                  label="Lựa chọn"
                  style="background-color:#2CB66B;color:white;max-height:36px"
                  :icon-right="active ? 'menu-up' : 'menu-down'"
                  
                />
              </template>

              <b-dropdown-item aria-role="listitem"  @click="onMenuClick(2)">Xác nhận</b-dropdown-item>
              <b-dropdown-item aria-role="listitem" @click="ViewImage()"
                >Xem chứng từ</b-dropdown-item
              >
              <b-dropdown-item aria-role="listitem" @click="print()"
                >In phiếu</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </div>
        <div
          v-if="
            data.Status.Code !== $getConst('FUNDSTATUS').WaitConfirm &&
            data.Status.Code !== $getConst('FUNDSTATUS').Cancelled
          "
          class="column cursor-pointer alight-right"
        >
          <div class="menu-dot">
            <b-dropdown position="is-top-left" append-to-body aria-role="menu">
              <template #trigger>
                <a role="button">
                  <span>
                    <img src="@/assets/img/circledoted.svg" />
                  </span>
                </a>
              </template>
              <span class="dropdown-menu-title">chức năng</span>
              <hr class="dropdown-divider" aria-role="menuitem" />
              <!-- <b-dropdown-item
                value="edit_pawn"
                aria-role="menuitem"
                class="menu-item"
              >
                <span>Xem chi tiết</span>
                <b-icon icon="eye" size="is-small" class="pad-left-20"></b-icon>
              </b-dropdown-item> -->
              <b-dropdown-item
                value="transaction_pawn"
                aria-role="menuitem"
                class="menu-item"
                @click="print()"
                v-if="
                  checkAccessRole(
                    $getConst('MODULE').FundReceive,
                    $getConst('ACTION').FundReceive.Print
                  )
                "
              >
                <span>In phiếu</span>
                <b-icon
                  icon="cloud-print-outline"
                  size="is-small"
                  class="pad-left-20"
                ></b-icon>
              </b-dropdown-item>
              <b-dropdown-item
                value="transaction_pawn"
                aria-role="menuitem"
                class="menu-item"
                @click="ViewImage()"
                v-if="
                  checkAccessRole(
                    $getConst('MODULE').FundTransferImages,
                    $getConst('ACTION').FundTransfer.Attactment
                  )
                "
              >
                <span>Xem chứng từ</span>
                <b-icon
                  icon="cloud-print-outline"
                  size="is-small"
                  class="pad-left-20"
                ></b-icon>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div :id="'print_' + idx" style="display: none" v-if="item != null">
      <PrintFundReceive :item="item" code="PC/HS/08/745" />
    </div>
  </div>
</template>
<script>
import PrintFundReceive from "@/modules/fundreceives/components/PrintFundReceive.vue";
import ConvertToDateTime from "@/helpers/ConvertDatetime.vue";
import store from "@/store";
import FundTransferService from "@/modules/fundtransfers/services/FundServices.vue";
import ToastHelper from "@/helpers/OpenToast.vue";
import convertDateTime from "@/helpers/ConvertDatetime.vue";
import Enum from "@/helpers/Enum.vue";
export default {
  props: ["data", "idx", "offset"],
  components: { PrintFundReceive },
  data() {
    return {
      CurrentUser: {
        Code: store.state.user.Profile.UserName,
        Name: store.state.user.Profile.FullName,
      },
      ConvertToDateTime: ConvertToDateTime,
      isDisableButton: false,
      item: null,
      dateReceive: "",
      shopSelected: null,
      Enum: Enum,
    };
  },
  mounted() {
    let _self = this;
    let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
    _self.shopSelected = shopSelected.BusinessOfficeCode;
    if (_self.data.DateReceive != null)
      _self.dateReceive = convertDateTime.ConvertUnixToDatetime(
        _self.data.DateReceive
      );
  },
  watch: {
    data: function (val) {
      console.log(val.DateReceive);
      if (val.DateReceive != null)
        this.dateReceive = convertDateTime.ConvertUnixToDatetime(
          val.DateReceive
        );
    },
  },
  methods: {
    onMenuClick(action) {
      var _self = this;

      switch (action) {
        case 1: // Hủy
          if (_self.isDisableButton) return;
          _self.isDisableButton = true;

          setTimeout(() => {
            try {
              _self.cancelFundReceive();
            } catch (e) {
              console.log(e);
            }
            _self.isDisableButton = false;
          }, 500);
          break;
        case 2: // Xác nhận
          if (_self.isDisableButton) return;
          _self.isDisableButton = true;

          setTimeout(() => {
            try {
              if (
                _self.CurrentUser.Code !=
                  _self.data.FundReceiveDetail.UserName &&
                _self.CurrentUser.Name != _self.data.FundReceiveDetail.FullName
              ) {
                _self.validateFundReceive();
              } else {
                _self.confirmFundReceive();
              }
            } catch (e) {
              console.log(e);
            }
            _self.isDisableButton = false;
          }, 500);
          break;
        case 3: // Chứng năng
          console.log("Chức năng");
          break;
      }
    },
    async ViewImage() {
      let _self = this;
      _self.$emit("GetDetail", _self.data.Id);
    },
    confirmFundReceive() {
      var _self = this;
      _self.Get_GetFundWaitingById();

      setTimeout(() => {
        if (_self.item == null || !_self.item) {
          ToastHelper.OpenToast(
            "Giao dịch không tồn tại hoặc đã được xác nhận",
            "is-warning"
          );
          return;
        }
        _self.$emit("onConfirmFund", _self.item);
      }, 500);
    },
    cancelFundReceive() {
      var _self = this;
      _self.Get_GetFundWaitingById();

      setTimeout(() => {
        if (_self.item == null || !_self.item) {
          ToastHelper.OpenToast(
            "Giao dịch không tồn tại hoặc đã được hủy",
            "is-warning"
          );
          return;
        }
        _self.$emit("onCancelFund", _self.item);
      }, 500);
    },
    validateFundReceive() {
      var _self = this;
      _self.Get_GetFundWaitingById();

      setTimeout(() => {
        if (_self.item == null || !_self.item) {
          ToastHelper.OpenToast(
            "Giao dịch không tồn tại hoặc đã được xác nhận",
            "is-warning"
          );
          return;
        }
        _self.$emit("onValidateFund", _self.item);
      }, 500);
    },
    async print() {
      var _self = this;
      // _self.item = await Service.getCollectPOSId(_self.contact.Id);
      _self.$nextTick(function () {
        _self.GetById();
        setTimeout(() => {
          _self.$htmlToPaper("print_" + _self.idx);
        }, 500);
      });
    },
    async GetById() {
      let _self = this;
      let result = await FundTransferService.Get_GetFundById(_self.data.Id);
      _self.item = result;
    },
    async Get_GetFundWaitingById() {
      let _self = this;
      let result = await FundTransferService.Get_GetFundWaitingById(
        _self.data.Id
      );
      console.log(result);
      _self.item = result;
    },
  },
};
</script>

<style scoped>
.button-cancel {
  background-color: transparent;
  border-color: red;
  color: red;
  width: 55px;
  height: 35px;
  margin-right: 5px;
  margin-left: -60px;
  text-align: center;
  font-size: 14px;
}
.button-confirm {
  background-color: transparent;
  background-color: green;
  color: white;
  height: 35px;
  width: 80px;
  margin-left: 5px;
  text-align: center;
  font-size: 14px;
}
.alight-right {
  text-align: right;
}
.btn-action {
  text-align: right;
}
.del {
  text-decoration: line-through;
}
.money-receive {
  color: green;
  font-weight: bold;
}
.word-wrap {
  word-wrap: break-word;
}
.note-cancel {
  text-decoration: underline;
  color: red;
}
</style>
