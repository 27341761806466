<script>
import Repository from "@/repositories/Repository.vue";
import RepositoryMasterData from "@/repositories/Masterdata/Repository.vue";
import Enum from "@/helpers/Enum.vue";
const Post_ListFund = async (item) => {
  return await Repository.post(Enum.API.FundReceive.List, item);
};
const Get_ListStatusFund = async () => {
  return await Repository.get(Enum.API.Category.ListStatusFund);
};
const Get_ListMethod = async (method) => {
  return await Repository.get(
    Enum.API.Category.ListMethod + `?method=${method}`
  );
};
const Get_ListBank = async () => {
  return await RepositoryMasterData.get(Enum.API.MasterData.ListBank);
};
const Get_ListPGD = async () => {
  return await RepositoryMasterData.get(Enum.API.MasterData.ListPGD);
};
const Post_ExportExcelFund = async (item) => {
  return await Repository.postDownload(Enum.API.FundReceive.ExportExcel, item);
};
const Get_ListPGDNew=async()=>{
  return await RepositoryMasterData.get(Enum.API.MasterData.PGDNew);
}
export default {
  Post_ListFund,
  Get_ListStatusFund,
  Get_ListMethod,
  Get_ListPGD,
  Get_ListBank,
  Post_ExportExcelFund,
  Get_ListPGDNew
};
</script>
