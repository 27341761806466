
 <template>
  <div class="modal-card modal-work-flow-content">
    <header class="modal-card-head">
      <div class="columns custom-card-head" style="width: 100%">
        <span class="hearder-liquidation">Hủy nhận quỹ</span>
      </div>
    </header>
    <section class="modal-card-body">
      <p class="fund-warning">Bạn đang thực hiện hủy lệnh nhận quỹ từ:</p>
      <p class="fund-info">
        {{ item.FundTransferDetail.FullName !== null ? item.FundTransferDetail.FullName : "" }} -
        {{ item.FundTransferDetail.ShopName !== null ? item.FundTransferDetail.ShopName : "" }}
      </p>
      <span>Số tiền: </span
      ><span class="money-fund">{{ formatCurrency(item.Total) }}</span>
      <p>Ghi chú</p>
      <b-input v-model="note" type="textarea"></b-input>
    </section>
    <footer class="modal-card-foot">
      <div class="center-button">
        <div class="footer-border-content-validate">
          <a @click="$emit('close')">Đóng</a>
          <b-button class="btn-next" @click="cancelFundReceive" :disabled="isDisabled"> Hủy </b-button>
        </div>
      </div>
    </footer>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import FundService from "@/modules/common/services/FundService.vue";
import ToastHelper from "@/helpers/OpenToast.vue";
export default {
  props: ["item"],
  data() {
    return {
      isLoading: false,
      note: "",
      isDisabled:false
    };
  },
  async mounted() {},
  methods: {
    async cancelFundReceive() {
      var _self = this;
      _self.isDisabled=true;
      _self.isLoading = true;
      var request = {
        note: _self.note,
        officeTransactionId: _self.item.OfficeTransactionId.toString()
      };
      let response = await FundService.Post_UndoTransactionFund(
        _self.item.Id,
        request
      );
      _self.isLoading = false;
      if (response!=null) {
        ToastHelper.OpenToast("Hủy nhận quỹ thành công", "is-success");
      } else {
        ToastHelper.OpenToast("Hủy nhận quỹ không thành công", "is-danger");
      }
      _self.$emit("close");
      _self.$emit("loadData");
    },
  },
};
</script>

<style lang="scss" scoped>

</style>