<template>
  <section>
    <div class="main-content">
      <div class="pawn-detail-content">
        <div class="header-bar-asset-config">
          <img class="back-btn" src="@/assets/img/prev-ac.png" alt />
          <p class="title-asset-config">NHẬN QUỸ NỘI BỘ</p>
        </div>
        <div class="filter is-gapless columns mar-10" :class="{ customerborder: shopSelected == Enum.OFFICE.HO }">
          <div class="column is-12">
            <div class="columns is-gapless dropdown-search-custom">
              <b-field class="column is-3">
                <b-datepicker
                  class=""
                  :locale="locale"
                  placeholder="Từ ngày đến ngày"
                  icon-right="calendar-today"
                  :years-range="[-100, 100]"
                  trap-focus
                  v-model="selectDate"
                  @input="onChangeDate"
                  range
                >
                </b-datepicker>
              </b-field>
              <b-field class="column is-2">
                <b-select
                  placeholder="Trạng thái"
                  v-model="filter.status"
                  @input="changeTranferStatus"
                  expanded
                >
                  <option
                    v-for="option in listStatus"
                    :value="option.Code"
                    :key="option.Code"
                  >
                    {{ option.Name }}
                  </option>
                </b-select>
              </b-field>
              <b-field class="column is-2">
                <b-select
                  placeholder="Hình thức"
                  v-model="filter.method"
                  @input="changeTransferMethod"
                  expanded
                >
                  <option
                    v-for="option in listMethod"
                    :value="option.Code"
                    :key="option.Code"
                  >
                    {{ option.Name }}
                  </option>
                </b-select>
              </b-field>
              <b-field class="column is-2">
                <b-select
                  placeholder="Ngân hàng nhận"
                  v-model="filter.bankCode"
                  @input="changeBankCode"
                  expanded
                >
                  <option
                    v-for="option in listBank"
                    :value="option.Code"
                    :key="option.Id"
                  >
                    {{ option.Value }}
                  </option>
                </b-select>
              </b-field>
              <b-field class="column is-3">
                <v-select
                  ref="model"
                  placeholder="PGD Chuyển"
                  class="style-chooser"
                  label="Name"
                  v-model="filter.shopCode"
                  :options="listShop"
                  :reduce="(option) => option.Code"
                  @input="changeShopCode"
                >
                  <div slot="no-options">Không có kết quả phù hợp</div>
                </v-select>
              </b-field>
            </div>
          </div>
        </div>
          <div
          class="filter is-gapless columns mar-10"
          :class="{ custombordertop: shopSelected == Enum.OFFICE.HO }"
          v-if="shopSelected == Enum.OFFICE.HO && lstShop.length > 0"
        >
          <div class="column is-3">
            <div class="columns is-gapless dropdown-search-custom">
              <b-field class="column is-12">
                <v-select
                  ref="model"
                  placeholder="PGD Nhận"
                  class="style-chooser"
                  label="Name"
                  v-model="shopCodeReceive"
                  :options="listShop"
                  :reduce="(option) => option.Code"
                  @input="GetListFundReceive"
                >
                  <div slot="no-options">Không có kết quả phù hợp</div>
                </v-select>
              </b-field>
            </div>
          </div>
        </div>
        <div>
          <PagingCustom :filter="filter" :total="total" />
          <div class="header-table">
            <div class="order">#</div>
            <div class="col">
              <div class="columns">
                <div class="column is-2 custom-w-10 text-align">Ngày</div>
                <div class="column is-2">Nhận từ</div>
                <div class="column is-1">Người nhận</div>
                <div class="column is-2">Xác nhận</div>
                <div class="column is-1">Số tiền</div>
                <div class="column is-1">Hình thức</div>
                <div class="column is-1">Ghi chú</div>
                <div class="column is-1">Trạng thái</div>
                <div class="column is-2"></div>
              </div>
            </div>
          </div>
          <div class="main-table tbl-bank-transfer-list">
            <div
              class="item-table"
              v-for="(item, idx) in listFundReceives"
              :key="item.Id"
            >
              <ItemFundReceive
                :idx="idx"
                :data="item"
                :offset="filter.offset"
                @onConfirmFund="onConfirmFund"
                @onCancelFund="onCancelFund"
                @onValidateFund="onValidateFund"
                @GetDetail="GetDetail"
              />
            </div>
          </div>
          <PagingItem :filter="filter" :total="total" />
        </div>
      </div>
    </div>
      <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </section>
</template>
<script>
import ItemFundReceive from "@/modules/fundreceives/components/ItemFundReceive.vue";
import FundReceiveService from "@/modules/fundreceives/services/FundReceiveService.vue";
import convertDateTime from "@/helpers/ConvertDatetime.vue";
import FundReceiveConfirmPopup from "@/modules/fundreceives/components/FundReceiveConfirmPopup.vue";
import FundReceiveCancelPopup from "@/modules/fundreceives/components/FundReceiveCancelPopup.vue";
import FundReceiveValidatePopup from "@/modules/fundreceives/components/FundReceiveValidatePopup.vue";
import PopupEditImage from "@/modules/fundtransfers/components/PopupEditImage.vue";
import FundTransferService from "@/modules/fundtransfers/services/FundServices.vue";
import Enum from "@/helpers/Enum.vue";
import ToastHelper from "@/helpers/OpenToast.vue";
import ConvertToDateTime from "@/helpers/ConvertDatetime.vue";
import PagingCustom from "@/modules/fundtransfers/components/PagingCustom.vue";
export default {
  components: {
    ItemFundReceive,
    PagingCustom,
  },
  data() {
    return {
      lstShop: [],
      locale: "vi-ve",
      total: 0,
      listFundReceives: [],
      listShop: [],
      listBank: [],
      listMethod: [],
      listStatus: [],
      fromDate: null,
      toDate: null,
      selectDate: [],
      filter: {
        offset: 0,
        limit: 10,
        search: null,
        fromDate: null,
        toDate: null,
        shopCode: null,
        status: null,
        method: null,
        bankCode: null,
      },
      SMData: {
        IdFund: null,
        ListImage: [],
      },
      ListImage: [],
      Enum: Enum,
      shopSelected: null,
      shopCodeReceive: null,
      isLoading:false
    };
  },
  mounted() {
    var _self = this;
  _self.checkURL();
    _self.$parent.$refs.info.isDis = false;
    let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
    _self.shopSelected = shopSelected.BusinessOfficeCode;
    _self.shopCodeReceive = shopSelected.Code;
    _self.GetListBank();
    _self.GetListPGD();

    _self.GetListFundStatus();
    _self.GetListMethod();
    _self.GetListFundReceive();
  },
  methods: {
    async GetListFundReceive() {
          var _self = this;
      let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      //_self.shopCodeReceive = shopSelected.Code;
      if (_self.selectDate[0] != null) {
        _self.filter.fromDate = ConvertToDateTime.ConvertDatetimeToUnix(
          _self.selectDate[0]
        );
        _self.filter.toDate = ConvertToDateTime.ConvertDatetimeToUnix(
          _self.selectDate[1]
        );
        let cal = ConvertToDateTime.CalcularDate(
          _self.selectDate[1],
          _self.selectDate[0]
        );
        if (cal > 30) {
          ToastHelper.OpenToast(
            "Bạn không thể xem dữ liệu vượt quá 30 ngày",
            "is-warning"
          );
          return;
        }
      }
      _self.filter.shopCodeReceive=shopSelected.Code;
      if (
        shopSelected &&
        shopSelected.BusinessOfficeCode != null &&
        shopSelected.BusinessOfficeCode == Enum.OFFICE.HO
      )
        _self.filter.shopCodeReceive = _self.shopCodeReceive;
      if (_self.filter.shopCodeReceive == null) {
        _self.listFundReceives = null;
        return;
      }
      var result = await FundReceiveService.Post_ListFund(_self.filter);
      _self.listFundReceives = result.Data;
      _self.total = result.Total;

      for (const element of _self.listFundReceives) {
        element.DateTransfer =
          element.DateTransfer != undefined
            ? convertDateTime.ConvertDateToStringDatetime(element.DateTransfer)
            : element.DateTransfer;
      }
    },
    pageChanged() {
      var _self = this;
      _self.GetListFundReceive();
      _self.$router.push({
        name: "FundReceiveList",
        query: {
          Offset: _self.filter.offset,
          Limit: _self.filter.limit,
          Search: _self.filter.search,
        },
      });
    },
    filterData() {
      var _self = this;
      _self.filter.offset = 0;
      _self.GetListFundReceive(_self.filter);
    },
    async GetListBank() {
      var _self = this;
      _self.listBank = [{ Code: "All", Value: "Tất cả" }];
      var result = await FundReceiveService.Get_ListBank();
      var lstBank = result.Data;
      _self.listBank.push.apply(_self.listBank, lstBank);
    },
    async GetListPGD() {
      var _self = this;
      _self.isLoading = true;
      _self.listShop = [{ Code: "All", Name: "Tất cả" }];
      var result = await FundReceiveService.Get_ListPGDNew();
      var lstShop = result.Data;
      _self.listShop.push.apply(_self.listShop, lstShop);
       _self.lstShop = lstShop;
      _self.isLoading = false;
    },
    async changeShopCode(value) {
      var _self = this;
      _self.filter.shopCode = value;
      _self.filterData();
    },
    async changeBankCode(value) {
      var _self = this;
      _self.filter.bankCode = value;
      _self.filterData();
    },
    async changeTransferMethod(value) {
      var _self = this;
      _self.filter.method = value;
      _self.filterData();
    },
    async changeTranferStatus(value) {
      var _self = this;
      _self.filter.status = value;
      _self.filterData();
    },
    async GetListFundStatus() {
      var _self = this;
      _self.listStatus = [{ Code: "All", Name: "Tất cả" }];
      var lstStatus = await FundReceiveService.Get_ListStatusFund();
      _self.listStatus.push.apply(_self.listStatus, lstStatus);
    },
    async GetListMethod() {
      var _self = this;
      _self.listMethod = [{ Code: "All", Name: "Tất cả" }];
      var lstMethod = await FundReceiveService.Get_ListMethod("01");
      _self.listMethod.push.apply(_self.listMethod, lstMethod);
    },
    async onChangeDate() {
      var _self = this;
      if (_self.selectDate[0] != null) {
        _self.filter.fromDate = convertDateTime.ConvertDatetimeToUnix(
          _self.selectDate[0]
        );
        _self.filter.toDate = convertDateTime.ConvertDatetimeToUnix(
          _self.selectDate[1]
        );
      }
      _self.filterData();
    },
    // onChangeToDate() {
    //   var _self = this;
    //   _self.filter.toDate = convertDateTime.ConvertDatetimeToUnix(_self.toDate);
    //   _self.GetListFundReceive(_self.filter);
    // },
    async onConfirmFund(item) {
      var _self = this;
      _self.$buefy.modal.open({
        component: FundReceiveConfirmPopup,
        hasModalCard: true,
        trapFocus: true,
        props: {
          item: item,
        },
        events: {
          loadData: () => _self.GetListFundReceive(_self.filter),
        },
      });
    },
    async onCancelFund(item) {
      var _self = this;
      _self.$buefy.modal.open({
        component: FundReceiveCancelPopup,
        hasModalCard: true,
        trapFocus: true,
        props: {
          item: item,
        },
        events: {
          loadData: () => _self.GetListFundReceive(_self.filter),
        },
      });
    },
    async onValidateFund(item) {
      var _self = this;
      _self.$buefy.modal.open({
        component: FundReceiveValidatePopup,
        hasModalCard: true,
        trapFocus: true,
        props: {
          item: item,
        },
        events: {
          loadData: () => _self.GetListFundReceive(_self.filter),
        },
      });
    },
    async ExportExcel() {
      let _self = this;
      let shopSelected = JSON.parse(localStorage.getItem("currentShop"));
      _self.filter.shopCodeReceive = shopSelected.Code;
      let filterModel = _self.filter;
      let result = await FundReceiveService.Post_ExportExcelFund(filterModel);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([result]));
      link.setAttribute("download", "danh_sach_nhan_quy.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    async GetDetail(val) {
      console.log("val", val);
      var result = await FundTransferService.Get_ListImage(val);
      var _self = this;
      _self.SMData.IdFund = val;
      _self.ListImage = null;
      _self.ListImage = result;
      _self.SMData.ListImage = result;
      _self.$buefy.modal.open({
        parent: _self,
        component: PopupEditImage,
        hasModalCard: true,
        trapFocus: true,
        props: {
          ListImage: _self.ListImage,
          SMData: _self.SMData,
          IsShow: false,
        },
        events: {},
      });
    },
  },
};
</script>

<style scoped></style>
